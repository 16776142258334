import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import { computed } from '@vue/composition-api';

export function useRetrievalQueryFilters(get: any) {
    const { reducedFacetsFilters, defaultValue } = useFacetsFilters();

    const retrievalQueryFilters = computed(
        (): Record<string, FacetFilter> => ({
            asset: {
                label: 'Asset',
                placeholder: 'Select asset',
                type: FacetFilterType.Treeselect,
                default: defaultValue(get, 'asset'),
                options: [],
            },
        }),
    );

    const reducedRetrievalQueryFilters: any = reducedFacetsFilters(retrievalQueryFilters);

    return { retrievalQueryFilters, reducedRetrievalQueryFilters };
}
