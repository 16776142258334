































































































































import {
    DataModelTree,
    Scrollbar,
    ShortTypeBadge,
    SvgImage,
    TwAccordion,
    TwAccordionCard,
    TwButton,
} from '@/app/components';
import { useAxios, useModelConcepts } from '@/app/composable';
import { Concept } from '@/app/interfaces';
import store from '@/app/store';
import { SearchUtils } from '@/modules/search/utils';
import { ContractsAPI } from '@/modules/sharing/api';
import { Contract } from '@/modules/sharing/types/contract.interface';
import { ChevronRightIcon, ExclamationIcon, LightningBoltIcon } from '@vue-hero-icons/outline';
import { DatabaseIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { JoinType, useRetrievalAPI } from '../composable';
import { RetrievalAccessibility, RetrievalAccessibilityType } from '../constants';
import { RetrievalConfiguration, SearchResult as SearchResultInterface } from '../interfaces';
import JoinSearchResults from './JoinSearchResults.vue';
import RetrievalQueryParameters from './RetrievalQueryParameters.vue';
import SearchAvailableResults from './SearchAvailableResults.vue';

export default defineComponent({
    name: 'SearchResultConfiguration',
    components: {
        Scrollbar,
        ChevronRightIcon,
        TwAccordionCard,
        TwAccordion,
        LightningBoltIcon,
        SvgImage,
        DataModelTree,
        ShortTypeBadge,
        DatabaseIcon,
        TwButton,
        SearchAvailableResults,
        ExclamationIcon,
        RetrievalQueryParameters,
        JoinSearchResults,
    },
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        configuration: {
            type: Object as PropType<RetrievalConfiguration>,
            default: () => {
                return {
                    params: {},
                    datasets: [],
                    download: 'direct',
                    kafkaConnectionDetails: {},
                    binaryConceptSelected: false,
                };
            },
        },
        accessibility: {
            type: Object as PropType<RetrievalAccessibilityType>,
            required: true,
        },
        assets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        allAssets: {
            type: Array as PropType<SearchResultInterface[]>,
            required: true,
        },
        totalResults: {
            type: Number,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        hasChanges: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { exec } = useAxios(true);
        const user = ref(store.state.auth.user);
        const { conceptsByUids } = useModelConcepts();

        const selectedAssets = ref<SearchResultInterface[]>([]);
        const selectedAssetIds: Ref<number[]> = ref<number[]>([]);
        const showAssets: Ref<boolean> = ref<boolean>(true);
        const concepts: Ref<Record<string, Concept>> = ref<Record<string, Concept>>({});
        const consolidatedSelectedFields: Ref<string[]> = ref<string[]>([]);
        const selectedQueryParameters: Ref<Record<string, { fieldType: string; filterType: string }>> = ref<
            Record<string, { fieldType: string; filterType: string }>
        >({});
        const selectedDatasets = computed(() => props.configuration.datasets);
        const { availableJoins } = useRetrievalAPI(selectedDatasets);
        const selectedJoin: Ref<{ type?: JoinType; configuration?: any }> = ref<{
            type?: JoinType;
            configuration?: any;
        }>({ type: JoinType.merge });

        const indexedFieldsPerAsset: Ref<
            { id: string; name: string | undefined; fields: string[]; indexed: string[] }[]
        > = computed(() =>
            selectedDatasets.value
                ? selectedDatasets.value.map((dataset: { id: string; fields: string[] }) => {
                      return {
                          ...dataset,
                          name: selectedAssets.value.find(
                              (asset: SearchResultInterface) => `${asset.id}` === `${dataset.id}`,
                          )?.name,
                          indexed: dataset.fields.filter(
                              (field: string) => selectedConceptsByPath.value[field]?.indexed,
                          ),
                      };
                  })
                : [],
        );

        const datasets: Ref<any[]> = computed(() =>
            selectedAssets.value.reduce((acc: any, asset: SearchResultInterface) => {
                acc[asset.id] = SearchUtils.createFieldPathsForSchemaAndConcepts(
                    asset.schema ?? asset.structure?.schema,
                    concepts.value,
                );
                acc[asset.id].contractId = asset.contractId ?? null;
                return acc;
            }, {}),
        );

        const extractPaths = (
            children: {
                concept: any;
                path: string;
                type: string;
                indexed: boolean;
                uid: string;
                children?: { concept: any; path: string; type: string; indexed: boolean; uid: string }[];
            }[],
            parentPaths: string[] = [],
        ) => {
            return children.reduce(
                (
                    acc: any,
                    child: {
                        concept: any;
                        path: string;
                        type: string;
                        indexed: boolean;
                        uid: string;
                        children?: { concept: any; path: string; type: string; indexed: boolean; uid: string }[];
                    },
                ) => {
                    // if path already exists and we happen to have a newer version
                    // of the concept then take the latest
                    if (
                        !R.has(child.path, acc) ||
                        child.concept?.majorVersion > (acc[child.path] as any)?.majorVersion
                    ) {
                        acc[`${[...parentPaths, child.path].join('.')}`] = {
                            concept: child.concept,
                            indexed: child.indexed,
                            type: child.type,
                            uid: child.uid,
                        };
                    }
                    if (child.children) acc = { ...acc, ...extractPaths(child.children, [...parentPaths, child.path]) };
                    return acc;
                },
                {},
            );
        };

        const selectedConceptsByPath = computed(() =>
            Object.values(datasets.value).reduce((acc: any, dataset: any) => {
                if (dataset.children) {
                    acc = { ...acc, ...extractPaths(dataset.children) };
                } else {
                    Object.keys(dataset).forEach((key: string) => {
                        acc[key] = {
                            type: dataset[key].type,
                            indexed: dataset[key].index,
                            uid: dataset[key].uid,
                        };
                    });
                }
                return acc;
            }, {}),
        );

        const availableQueryParameters = computed(() =>
            consolidatedSelectedFields.value.reduce((acc: string[], conceptKey: string) => {
                const concept = selectedConceptsByPath.value[conceptKey];
                if (
                    concept &&
                    concept.indexed &&
                    !Object.keys(selectedQueryParameters.value).includes(conceptKey) &&
                    concept.type &&
                    concept.type !== 'base64binary'
                ) {
                    acc.push(conceptKey);
                }
                return acc;
            }, []),
        );

        const availableQueryParametersForDropdown = (currentKey: string) => {
            return [currentKey, ...availableQueryParameters.value].map((param: string) => {
                return { label: param };
            });
        };

        const retrievalQueryConfiguration: Ref<RetrievalConfiguration> = computed(() => {
            return {
                binaryConceptSelected: Object.values(selectedConceptsByPath.value).some(
                    (concept: any) => concept.type === 'base64binary',
                ),
                datasets: Object.keys(datasets.value).map((id: string) => {
                    return {
                        id: `${id}`,
                        fields: datasets.value[id].children
                            ? Object.keys(extractPaths(datasets.value[id].children)).reduce(
                                  (acc: string[], path: string) => {
                                      if (consolidatedSelectedFields.value.includes(path)) acc.push(path);
                                      return acc;
                                  },
                                  [],
                              )
                            : [],
                        contractId: datasets.value[id].contractId,
                    };
                }),
                download: 'direct',
                join: selectedJoin.value,
                kafkaConnectionDetails: {},
                params: selectedQueryParameters.value,
            };
        });

        const atLeastOneFieldSelected = computed(
            () =>
                retrievalQueryConfiguration.value.datasets.length > 0 &&
                !retrievalQueryConfiguration.value.datasets.some(
                    (dataset: { id: string; fields: string[] }) => dataset.fields.length === 0,
                ),
        );

        const selectionChanged = (datasetId: number, paths: string[]) => {
            if (props.configuration.datasets) {
                const currentDataset = props.configuration.datasets.find(
                    (dataset: { fields: string[]; id: string; contractId: string | null }) =>
                        `${dataset.id}` === `${datasetId}`,
                );
                const currentDatasetFields = currentDataset ? currentDataset.fields : [];

                consolidatedSelectedFields.value = R.uniq([
                    ...paths,
                    ...props.configuration.datasets.reduce(
                        (acc: string[], dataset: { fields: string[]; id: string; contractId: string | null }) => {
                            if (`${dataset.id}` !== `${datasetId}`)
                                acc = [
                                    ...acc,
                                    ...dataset.fields.filter((f: string) => !currentDatasetFields.includes(f)),
                                ];

                            return acc;
                        },
                        [],
                    ),
                ]);
            }
        };

        const processConfiguration = (newConfiguration: RetrievalConfiguration) => {
            let newJoin = newConfiguration.join;
            // if join no longer available then remove
            if (
                !newJoin?.type ||
                availableJoins.value.length === 0 ||
                !availableJoins.value.some((availableJoin: { type: JoinType }) => availableJoin.type === newJoin.type)
            )
                newJoin = { type: JoinType.merge };

            const newAssetIds = newConfiguration.datasets
                ? newConfiguration.datasets.map((asset: { id: string; fields: string[]; contractId: string | null }) =>
                      parseInt(asset.id, 10),
                  )
                : [];

            const newConsolidatedSelectedFields = newConfiguration.datasets
                ? newConfiguration.datasets.reduce(
                      (acc: string[], asset: { id: string; fields: string[]; contractId: string | null }) => {
                          return R.uniq([...acc, ...asset.fields]);
                      },
                      [],
                  )
                : [];

            if (JSON.stringify(newConsolidatedSelectedFields) !== JSON.stringify(consolidatedSelectedFields.value))
                consolidatedSelectedFields.value = newConsolidatedSelectedFields;

            const newParams = Object.keys(newConfiguration.params || {}).reduce((acc: any, param: string) => {
                if (consolidatedSelectedFields.value.includes(param)) acc[param] = newConfiguration.params[param];
                return acc;
            }, {});

            if (JSON.stringify(newAssetIds) !== JSON.stringify(selectedAssetIds.value))
                selectedAssetIds.value = newAssetIds;

            if (JSON.stringify(newParams) !== JSON.stringify(selectedQueryParameters.value))
                selectedQueryParameters.value = newParams;

            if (JSON.stringify(newJoin) !== JSON.stringify(selectedJoin.value)) selectedJoin.value = newJoin;
        };

        const assetSelections = (id: string | number) => {
            const dataset = props.configuration.datasets?.find(
                (d: { id: string; fields: string[] }) => `${d.id}` === `${id}`,
            );
            return dataset ? dataset.fields : [];
        };

        const assetsToBeSelected = computed(() =>
            props.allAssets.filter((asset: SearchResultInterface) => selectedAssetIds.value.includes(asset.id)),
        );

        watch(
            () => assetsToBeSelected.value,
            async (selected: SearchResultInterface[], oldSelection: SearchResultInterface[]) => {
                if (R.equals(selected, oldSelection)) return;
                const requiredConceptUids = selected.reduce(
                    (acc: { uids: string[]; majorVersion: number; domainUid: string }[], s: SearchResultInterface) => {
                        // fetch concepts if we have a data model
                        if (s.structure?.domain) {
                            const uids: string[] = (s.schema ?? s.structure?.schema)
                                .map((con: any) => con.uid)
                                .filter(String);
                            let found: boolean = false;
                            const updatedAcc: { uids: string[]; majorVersion: number; domainUid: string }[] = acc.map(
                                (entry: { uids: string[]; majorVersion: number; domainUid: string }) => {
                                    if (
                                        entry.domainUid === s.structure.domain.uid &&
                                        entry.majorVersion === s.structure.domain.majorVersion
                                    ) {
                                        found = true;

                                        return { ...entry, uids: R.uniq([...entry.uids, ...uids]) };
                                    }
                                    return entry;
                                },
                            );

                            if (!found)
                                updatedAcc.push({
                                    uids,
                                    majorVersion: s.structure.domain.majorVersion,
                                    domainUid: s.structure.domain.uid,
                                });
                            return updatedAcc;
                        }
                        return acc;
                    },
                    [],
                );

                if (requiredConceptUids.length > 0) {
                    concepts.value = await conceptsByUids(requiredConceptUids);
                }

                // In case of acquired assets, filter schema based on signed contract
                const acquiredAssets = selected.filter(
                    (asset: SearchResultInterface) => asset.createdBy.organisationId !== user.value.organisationId,
                );
                const acquiredAssetIds = R.pluck('id', acquiredAssets);

                if (acquiredAssetIds.length) {
                    const res = await exec(ContractsAPI.getByAssetIds(acquiredAssetIds.join(',')));
                    for (const acquiredAsset of acquiredAssets) {
                        const foundContract = res?.data.find(
                            (contract: Contract) => contract.assetId == acquiredAsset.id,
                        );
                        if (foundContract) {
                            const selectedFieldNames = R.pluck('name', foundContract.fields as any[]);
                            acquiredAsset.schema = acquiredAsset.schema.filter((item: any) =>
                                selectedFieldNames.includes([...item.path, item.field].join('.')),
                            );
                            acquiredAsset.contractId = foundContract.id;
                        }
                    }
                }
                selectedAssets.value = selected;
            },
        );

        watch(
            () => retrievalQueryConfiguration.value,
            (configuration: RetrievalConfiguration, oldConfiguration: RetrievalConfiguration) => {
                if (JSON.stringify(configuration) !== JSON.stringify(oldConfiguration)) {
                    emit('change', configuration);
                }
            },
            { deep: true },
        );

        watch(
            () => props.configuration,
            (newConfiguration: RetrievalConfiguration) => {
                processConfiguration(newConfiguration);
            },
            { immediate: true, deep: true },
        );

        return {
            datasets,
            selectedAssetIds,
            selectedAssets,
            showAssets,
            RetrievalAccessibility,
            concepts,
            consolidatedSelectedFields,
            selectedQueryParameters,
            selectedConceptsByPath,
            availableQueryParameters,
            atLeastOneFieldSelected,
            availableJoins,
            selectedJoin,
            indexedFieldsPerAsset,
            assetSelections,
            selectionChanged,
            availableQueryParametersForDropdown,
            emit,
        };
    },
});
