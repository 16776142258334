import { ESortingOption } from '@/app/types';
import { isNil } from 'ramda';

export class RetrievalQuerySortingOption extends ESortingOption {
    static readonly TITLE_ASC = new RetrievalQuerySortingOption('title', true, 'Title Ascending');

    static readonly TITLE_DESC = new RetrievalQuerySortingOption('title', false, 'Title Descending');

    static readonly DATE_CREATED_DESC = new RetrievalQuerySortingOption(
        'date_created',
        false,
        'Date created newest first',
    );

    static readonly DATE_CREATED_ASC = new RetrievalQuerySortingOption(
        'date_created',
        true,
        'Date created oldest first',
    );

    static readonly DATE_UPDATED_DESC = new RetrievalQuerySortingOption(
        'date_updated',
        false,
        'Date updated newest first',
    );

    static readonly DATE_UPDATED_ASC = new RetrievalQuerySortingOption(
        'date_updated',
        true,
        'Date updated oldest first',
    );

    static readonly STATUS_ASC = new RetrievalQuerySortingOption('status', true, 'Status Ascending');

    static readonly STATUS_DESC = new RetrievalQuerySortingOption('status', false, 'Status Descending');

    static all(): ESortingOption[] {
        return [...super.all(), ...this.local()];
    }

    static default(relevanceAvailable: boolean): ESortingOption {
        const calculatedDefault = super.calculateDefault(relevanceAvailable);
        if (!isNil(calculatedDefault)) return calculatedDefault;

        return this.DATE_UPDATED_DESC;
    }

    static available(relevanceAvailable: boolean): ESortingOption[] {
        return [...super.available(relevanceAvailable), ...this.local()];
    }

    private static local(): RetrievalQuerySortingOption[] {
        return [
            this.DATE_CREATED_DESC,
            this.DATE_CREATED_ASC,

            this.DATE_UPDATED_DESC,
            this.DATE_UPDATED_ASC,

            this.STATUS_DESC,
            this.STATUS_ASC,

            this.TITLE_DESC,
            this.TITLE_ASC,
        ];
    }
}
