import { FacetFilter, FacetFilterType, useFacetsFilters } from '@/app/composable';
import { computed } from '@vue/composition-api';
import { RetrievalAccessibility, RetrievalStatus } from '../constants';

export function useRetrievalQueryFacets(get: any) {
    const { reducedFacetsFilters, defaultValue, capitalize } = useFacetsFilters();

    const retrievalQueryFacets = computed(
        (): Record<string, FacetFilter> => ({
            accessibility: {
                label: 'Type',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'accessibility'),
                valueLabel: (type: string) => (type === RetrievalAccessibility.API ? type : capitalize(type)),
            },
            status: {
                label: 'Status',
                type: FacetFilterType.Checkbox,
                default: defaultValue(get, 'status'),
                valueLabel: (type: string) => {
                    switch (type) {
                        case RetrievalStatus.Available:
                            return 'Assets Available';
                        case RetrievalStatus.Configuration:
                            return 'Configuration';
                        case RetrievalStatus.Deprecated:
                            return 'Assets Deleted';
                        case RetrievalStatus.Unavailable:
                            return 'Assets Unavailable';
                        default:
                            return type;
                    }
                },
            },
        }),
    );

    const reducedRetrievalQueryFacets: any = reducedFacetsFilters(retrievalQueryFacets);

    return { retrievalQueryFacets, reducedRetrievalQueryFacets };
}
