









































































































































































































































































































import { CopyToClipboardButton, JsonEditor, TwAccordion, TwAccordionCard, AlertBanner } from '@/app/components';
import { useFeatureFlags } from '@/app/composable';
import { AccessTokenScope } from '@/app/constants/scopes';
import { SearchUtils } from '@/modules/search/utils';
import AccessTokenModal from '@/modules/user/views/AccessTokenModal.vue';
import { PropType, Ref, computed, defineComponent, ref } from '@vue/composition-api';
import { QueryParameterTypes } from '../constants';

export default defineComponent({
    name: 'ApiResultsAcquisitionInformation',
    components: { TwAccordion, TwAccordionCard, CopyToClipboardButton, JsonEditor, AccessTokenModal, AlertBanner },
    model: {
        prop: 'configuration',
        event: 'change',
    },
    props: {
        uid: { type: String, required: true },
        configuration: {
            type: Object as PropType<{
                binaryConceptSelected?: boolean;
                datasets?: { fields: string[]; id: string }[];
                download?: string;
                join?: any;
                kafkaConnectionDetails?: any;
                params?: any;
            }>,
            default: () => {
                return {
                    params: {},
                    datasets: [],
                    download: 'direct',
                    kafkaConnectionDetails: {},
                    binaryConceptSelected: false,
                };
            },
        },
    },
    setup(props) {
        const { areAnyEnabled } = useFeatureFlags();
        const showAccessTokenModal: Ref<boolean> = ref<boolean>(false);
        const apiUrl = `${process.env.VUE_APP_BACKEND_URL}/api/retrieval` || '';
        const unitsUrl = `${process.env.VUE_APP_BACKEND_URL}/api/retrieval/${props.uid}/units`;

        const selectedQueryParameters = computed(() => Object.keys(props.configuration.params));
        const fields = computed(() =>
            Object.keys(props.configuration.params).reduce((acc: any, p: string) => {
                acc[p] = {
                    queryParameterType: props.configuration.params[p].filterType || QueryParameterTypes.Value,
                    type: props.configuration.params[p].fieldType,
                };
                return acc;
            }, {}),
        );

        const computedApi = computed(() => {
            return SearchUtils.computeApi(apiUrl, props.uid || '', [...selectedQueryParameters.value], fields.value);
        });

        const availableScopes = [AccessTokenScope.Retrieve]
            .filter((scope) => areAnyEnabled(scope.features))
            .map((scope) => {
                return { ...scope, checked: true, disabled: true };
            });

        return {
            fields,
            apiUrl,
            unitsUrl,
            computedApi,
            showAccessTokenModal,
            AccessTokenScope,
            availableScopes,
        };
    },
});
