import { useAxios, useQueryParams } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { AssetsAPI } from '@/modules/asset/api';
import { AssetTypeId, StatusCode } from '@/modules/asset/constants';
import { SearchAPI } from '@/modules/search/api';
import { Ref, ref } from '@vue/composition-api';
import { RetrievalAccessibilityType, RetrievalSortingOption } from '../constants';

export function useRetrievalAvailableAssets(accessibility: Ref<RetrievalAccessibilityType | undefined>, root: any) {
    const router = useRouter();
    const { error, loading, exec } = useAxios(true);
    const { get, set } = useQueryParams(root, router, 'retrieval:configure');

    const allAssets = ref<any[]>([]);
    const hiddenSelectedAssets = ref<any[]>([]);
    const hiddenSelectedAssetIds = ref<number[]>([]);
    const totalResults = ref<number>(0);
    const assets: Ref<any[] | undefined> = ref<any[]>();
    const sortBy = get('sortBy') ? RetrievalSortingOption.findByLabel(get('sortBy')) : null;
    const sortByObject: any = sortBy ? sortBy : RetrievalSortingOption.DATE_UPDATED_DESC;
    const searchQuery = ref<any>({
        query: {
            input: { text: get('search') ? get('search') : '*' },
            settings: { caseSensitive: false, partialMatch: true },
            ids: [],
        },
        facets: {
            assetType: [AssetTypeId.Dataset, AssetTypeId.Result],
            status: [StatusCode.Available, StatusCode.Internal, StatusCode.Pending],
        },
        filters: { isLocal: false, accessibility: accessibility.value ? [accessibility.value.name] : [] },
        pagination: { page: get('page') ? parseInt(get('page'), 10) : 1, size: 10 },
        sortBy: { field: sortByObject.field, asc: sortByObject.asc },
        settings: {},
    });

    const getSearchAPI = () => {
        if (accessibility.value)
            switch (accessibility.value) {
                case RetrievalAccessibilityType.API:
                    return SearchAPI.assetsSearchRetrieveViaAPI;
                case RetrievalAccessibilityType.FILE:
                    return SearchAPI.assetsSearchRetrieveAsFile;
                default:
                    return null;
            }
        return null;
    };

    const refetch = (selectedAssetIds: number[], fetchHiddenSelectedAssets = false) => {
        return new Promise<void>((resolve, reject) => {
            searchQuery.value.filters.accessibility = accessibility.value ? [accessibility.value.name] : [];
            searchQuery.value.query.ids =
                searchQuery.value.sortBy.field === RetrievalSortingOption.SELECTED.field ? selectedAssetIds : [];

            const api = getSearchAPI();
            if (!api) {
                reject();
                return;
            }

            exec(api(searchQuery.value))
                .then((res: any) => {
                    totalResults.value = res.data?.total;
                    assets.value = res.data?.results;
                    if (assets.value) {
                        for (let i = 0; i < assets.value.length; i++) {
                            const asset = assets.value[i];
                            if (!allAssets.value.find((a: any) => a.id === asset.id)) allAssets.value.push(asset);
                        }
                        if (fetchHiddenSelectedAssets) {
                            hiddenSelectedAssets.value = [];
                            hiddenSelectedAssetIds.value = selectedAssetIds.filter(
                                (id: number) => !assets.value?.find((asset: any) => asset.id === id),
                            );
                            for (let i = 0; i < hiddenSelectedAssetIds.value.length; i++) {
                                exec(AssetsAPI.getAsset(hiddenSelectedAssetIds.value[i])).then((resAsset: any) => {
                                    hiddenSelectedAssets.value.push(resAsset.data);
                                    allAssets.value.push(resAsset.data);
                                });
                            }
                        }
                    }
                    resolve();
                })
                .catch((e) => reject(e));
        });
    };

    const updateSearchQuery = (
        sQuery: { page?: number; text?: string; sortBy?: { field: string; asc: boolean } },
        selectedAssetIds: number[],
    ) => {
        const keys = Object.keys(sQuery);
        if (keys.includes('page')) searchQuery.value.pagination.page = sQuery.page;
        if (keys.includes('text')) searchQuery.value.query.input.text = sQuery.text ? sQuery.text : '*';
        if (keys.includes('sortBy'))
            searchQuery.value.sortBy = { field: sQuery.sortBy?.field, asc: sQuery.sortBy?.asc };
        refetch(selectedAssetIds);
    };

    return {
        assets,
        totalResults,
        error,
        loading,
        allAssets,
        hiddenSelectedAssets,
        hiddenSelectedAssetIds,
        refetch,
        get,
        set,
        updateSearchQuery,
    };
}
